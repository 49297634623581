import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { gatewayFormValidator } from "../../../../utils/gatewayFormValidator";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import QRCodeCard from "../../../../utils/QrCodeCard";
import { updateDepositAmount } from "../../../../redux/user/actions";
import { APP_CONST } from "../../../../config/const";
import { Toaster } from "../../../../utils/toaster";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import { Container } from "react-bootstrap";
const WithdrawGateway = () => {
  const { selectedGateway, gatewaysList } = useSelector((state) => state.user);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  // console.log("selectedGateway", selectedGateway);
  const validateData = (dataToValidate) => {
    let fields = selectedGateway.fields;
    let validations = gatewayFormValidator(fields, dataToValidate);
    setErrors(validations);
    return validations;
  };
  const handleChange = (e, type = "non-file") => {
    let name = e.target.name;
    let value = e.target.value;
    if (type === "file") {
      value = e.target.files?.[0];
    }
    let data = { ...formData, [name]: value };
    validateData(data);
    setFormData(data);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.values(validateData({ ...formData })).length == 0) {
      // console.log("formData", formData);
      if (
        selectedGateway.fields.some(
          (f) => f?.accessor === "gateway" && f?.required === true
        )
      ) {
        formData["gateway"] = selectedGateway?.code;
      }
      const response = await updateDepositAmount({
        data: formData,
        url: selectedGateway?.url,
        isMultiPart: selectedGateway?.fields.some((f) => f?.type === "file"),
      });
      handleSubmitResponse(response);
      // console.log("response", response);
    }
  };

  const handleSubmitResponse = (value) => {
    if (value) {
      if (selectedGateway.type === "manual") {
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, value.data.data.message);
      } else if (selectedGateway.code === "hpay") {
        window.location.href = value?.data?.data?.data?.payUrl;
      } else if (selectedGateway.code === "alphapo") {
        window.location.href = value?.data?.data?.data?.url;
      } else if (selectedGateway.code === "floxypay") {
        window.location.href = value?.data.data.data?.payUrl;
      } else if (selectedGateway.code === "coinbase") {
        window.location.href = value?.data.data.data?.payUrl;
      } else if (selectedGateway.code === "binancepay") {
        window.location.href = value?.data.data.data?.payUrl;
      }
    }
  };

  const renderFields = (field) => {
    switch (field.type) {
      case "file":
        return (
          <Form.Group
            className="mb-3 position-relative"
            controlId="formBasicUserName"
          >
            <Form.Control
              type={field.type}
              placeholder={field.label}
              name={field.accessor}
              onChange={(e) => handleChange(e, "file")}
              style={{ display: !field.display ? "none" : "" }}
              isInvalid={!!errors[field.accessor]}
            />
            <span class="focus-border">
              <i></i>
            </span>
            <Form.Control.Feedback type="invalid">
              {errors[field.accessor]}
            </Form.Control.Feedback>
          </Form.Group>
        );
      default:
        return (
          <Form.Group
            className="mb-3 position-relative"
            controlId="formBasicUserName "
          >
            <Form.Control
              type={field.type}
              placeholder={field.label}
              name={field.accessor}
              onChange={handleChange}
              style={{ display: !field.display ? "none" : "" }}
              isInvalid={!!errors[field.accessor]}
            />
            <span class="focus-border">
              <i></i>
            </span>
            <Form.Control.Feedback type="invalid">
              {errors[field.accessor]}
            </Form.Control.Feedback>
          </Form.Group>
        );
    }
  };
  return (
    <>
      <HeaderAfterLogin />
      <main className="main mt-5">
        <Container>
          <div className="heading">{selectedGateway?.name}</div>
          <Form className="gatewayForm">
            {selectedGateway?.fields?.map((f, i) => {
              return renderFields(f);
            })}
            <span class="focus-border">
              <i></i>
            </span>
            <Button onClick={handleSubmit}>Submit</Button>
          </Form>
          {selectedGateway?.type === "manual" && (
            <QRCodeCard text={selectedGateway?.value} />
          )}
        </Container>
      </main>
      <Footer />
    </>
  );
};
export default WithdrawGateway;
