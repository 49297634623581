import React, { useEffect } from "react";
import { Button, Container, Dropdown, Tab, Table, Tabs } from "react-bootstrap";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { FiUsers } from "react-icons/fi";
import { AiFillCopy } from "react-icons/ai";
import { IoDiamond } from "react-icons/io5";
import { API_URLS } from "../../../../config/apiUrls";
import { useSWRFetcher } from "../../../../hooks/useSWRHooks";
import { useClipboard } from "@mantine/hooks";
const Referral = () => {
  const navigate = useNavigate();

  const clipboard = useClipboard({ timeout: 500 });

  const { data: referralGenerate } = useSWRFetcher({
    url: API_URLS.REFERRAL.GENERATE,
    isExternalFetch: true,
  });

  const { data: refStats } = useSWRFetcher({
    url: API_URLS.REFERRAL.STATS,
    isExternalFetch: true,
    swrConfig: {
      refreshInterval: 20_000,
    },
  });
  // console.log("stats", refStats);

  // const { data: refChildren } = useSWRFetcher({
  //   url: API_URLS.REFERRAL.CHILDREN,
  //   isExternalFetch: true,
  // });
  // console.log("Children", refChildren);

  const { data: refSource } = useSWRFetcher({
    url: API_URLS.REFERRAL.SOURCES,
    isExternalFetch: true,
    swrConfig: {
      refreshInterval: 20_000,
    },
  });
  // console.log("Source", refSource);

  // const refSource = {
  //   urls: [
  //     {
  //       domain: "ty-test.com",
  //       visitCount: 240,
  //       urls: [
  //         {
  //           url: "/string",
  //           count: 120,
  //         },
  //         {
  //           url: "/string",
  //           count: 120,
  //         },
  //       ],
  //     },
  //   ],
  // };
  return (
    <div>
      {" "}
      <HeaderAfterLogin />
      <main className="main mt-5">
        <div className="container">
          <Container>
            <div className="heading">
              <h4>Referrals</h4>
              <div className="back-link" onClick={() => navigate(-1)}>
                Back <BackIcon />
              </div>
            </div>
            <div className=" ">
              <div className="referral_card_main mt-4">
                <div className="referrals_cards p-4">
                  <h5>Total Reffered Users</h5>
                  <div className="referr_user">
                    <FiUsers className="referral_card_icon" /> <p>{refStats?.users}</p>
                  </div>
                </div>
                <div className="referrals_cards p-4">
                  <h5>Total Bonus Earned</h5>
                  <div className="referr_user">
                    <IoDiamond className="referral_card_icon" /> <p>{refStats?.amount}</p>
                  </div>
                </div>
                <div className="referrals_cards p-4">
                  <h5>Total Link Visits</h5>
                  <div className="referr_user">
                    <IoDiamond className="referral_card_icon" /> <p>{refStats?.visits}</p>
                  </div>
                </div>
              </div>
              <div className="referral_link mt-4">
                <h5>Refferal Code </h5> <div>{referralGenerate?.referralCode}</div>
                <Button
                  className=""
                  onClick={() => clipboard.copy(referralGenerate?.referralLink)}
                >
                  <AiFillCopy /> {clipboard.copied ? "Copied" : "Copy Link"}
                </Button>
              </div>

              <div className="referr_tabs mt-5">
                <Tabs
                  defaultActiveKey="Source"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  {/* <Tab eventKey="Referred" title="Referred Users">
                    <div className="table-responsive ">
                      <Table className="table referr_table ">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Bonus</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Tommy Lee</td>
                            <td>{refStats?.amount}</td>
                            <td>
                              {startdate} - {endDate}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Tab> */}
                  <Tab eventKey="Source" title="Sources">
                    <div className=" ">
                      <Table className="table referr_table ">
                        <thead>
                          <tr>
                            <th>Domain</th>
                            <th>Visit Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {refSource &&
                            Array.isArray(refSource?.urls) &&
                            refSource?.urls.map((item) => (
                              <tr key={item?.domain}>
                                <td>{item?.domain}</td>
                                <td>
                                  {" "}
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="success"
                                      id="dropdown-basic"
                                    >
                                      {item?.visitCount}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {Array.isArray(item.urls) &&
                                        item.urls.map((urlpaths) => (
                                          <Dropdown.Item
                                            key={item?.domain + urlpaths.url}
                                            className="border-bottom"
                                          >
                                            <div>{urlpaths.url}</div>{" "}
                                            <div>{urlpaths.count}</div>
                                            <Dropdown.Divider />
                                          </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Container>
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default Referral;
