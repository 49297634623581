import React from "react";
import { useSelector } from "react-redux";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import PlayBtn from "../../../../assets/images/play-btn.png";
import Spribe1 from "../../../../assets/images/spribe/SpribeImg1.jpg";
import Spribe2 from "../../../../assets/images/spribe/SpribeImg2.png";
import Spribe3 from "../../../../assets/images/spribe/SpribeImg3.jpg";
import Spribe4 from "../../../../assets/images/spribe/SpribeImg4.png";
import Spribe5 from "../../../../assets/images/spribe/SpribeImg5.jpg";
import Spribe6 from "../../../../assets/images/spribe/SpribeImg6.png";
import Spribe7 from "../../../../assets/images/spribe/SpribeImg7.png";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../../../assets/svg/BackIcon";
import { spribeData } from "../../../../lib/data/spripe.data";

const Spribe = () => {
  const navigate = useNavigate();

  const { isAuth } = useSelector((state) => state.auth);
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>

      <main className="main">
        <div className="slots-section spribesection mt-5">
          <div className="games-section">
            <div className="game-heading d-flex justify-content-between">
              <span>Spribe</span>
              <div className="back-link" onClick={() => navigate(-1)}>
                Back <BackIcon />
              </div>
            </div>
            <ul>
              {Object.values(spribeData).map((item) => {
                return (
                  <li className="game-section">
                    <a href={isAuth ? item.redirectUrl : "/login"}>
                      <img src={item.imgUrl} />
                      <div className="casino_overlay">
                        <img src={PlayBtn} />
                      </div>
                      <p>{item.name}</p>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </main>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Spribe;
