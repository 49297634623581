export const spribeData = {
  spribe1: {
    redirectUrl: "/casino/spribe/mines",
    imgUrl: "./images/spribe/SpribeImg1.jpg",
    name: "Mines",
  },
  spribe2: {
    redirectUrl: "/casino/spribe/goal",
    imgUrl: "./images/spribe/SpribeImg2.png",
    name: "Goal",
  },
  spribe3: {
    redirectUrl: "/casino/spribe/dice",
    imgUrl: "./images/spribe/SpribeImg3.jpg",
    name: "Dice",
  },
  spribe4: {
    redirectUrl: "/casino/spribe/aviator",
    imgUrl: "./images/spribe/SpribeImg4.png",
    name: "Aviator",
  },
  spribe5: {
    redirectUrl: "/casino/spribe/plinko",
    imgUrl: "./images/spribe/SpribeImg5.jpg",
    name: "Plinko",
  },
  spribe6: {
    redirectUrl: "/casino/spribe/miniroulette",
    imgUrl: "./images/spribe/SpribeImg6.png",
    name: "Mini Roulette",
  },
  spribe7: {
    redirectUrl: "/casino/spribe/hilo",
    imgUrl: "./images/spribe/SpribeImg7.png",
    name: "Hilo",
  },
};
