import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { Container, ProgressBar } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import BackIcon from "../../../../assets/svg/BackIcon";
import Footer from "../../../../containers/Footer";

import { API_URLS } from "../../../../config/apiUrls";
import {
  useInvalidateSWRKeys,
  useSWRFetcher,
} from "../../../../hooks/useSWRHooks";
import { AxiosApiHelper } from "../../../../utils/axiosService";

const BonusCard = () => {
  const params = useParams();
  const navigate = useNavigate();
  const invalidateKeys = useInvalidateSWRKeys();

  const { data: bonusStats } = useSWRFetcher({
    url: params.id ? `${API_URLS.BONUS.STATS}/${params.id}` : null,
    isExternalFetch: true,
    swrConfig: {
      // onError: (err, key, config)=>console.log(err,)
    },
  });

  const { data: bonusParts, error: bonusPartsError } = useSWRFetcher({
    url: params.id ? `${API_URLS.BONUS.PARTS}/${params.id}` : null,
    isExternalFetch: true,
  });

  // console.log(bonusParts);
  // console.log(bonusPartsError);

  const handleCancelBonus = async (bonusId) => {
    await AxiosApiHelper({
      method: "patch",
      url: `${API_URLS.BONUS.CANCEL}/${bonusId}`,
    });

    // refetch data for these cached queries
    invalidateKeys([
      `${API_URLS.BONUS.STATS}/${params.id}`,
      `${API_URLS.BONUS.PARTS}/${params.id}`,
    ]);
  };

  return (
    <div>
      <HeaderAfterLogin />
      <main className="main mt-5">
        <div className="container">
          <Container>
            <div className="heading">
              <h4>Bonus Cards</h4>
              <div className="back-link" onClick={() => navigate(-1)}>
                Back <BackIcon />
              </div>
            </div>

            <div className="primary_card mt-5">
              {bonusStats && (
                <div className="stats-card">
                  <ul>
                    <li>
                      Bonus Name:
                      <span>{bonusStats?.bonusName}</span>
                    </li>
                    <li>
                      Bonus Code: <span>{bonusStats.bonusCode}</span>
                    </li>
                    <li>
                      Activated:<span> {bonusStats?.createdAt}</span>
                    </li>
                    <li>
                      Amount:
                      <span>
                        {bonusStats?.amount?.credited} /{" "}
                        {bonusStats?.amount?.creditable}
                      </span>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      Bonus Type:<span>{bonusStats?.bonusType}</span>{" "}
                    </li>

                    <li>
                      Parts:
                      <span>
                        {" "}
                        {bonusStats?.parts?.claimed} /{" "}
                        {bonusStats?.parts?.total}
                      </span>
                    </li>
                    <li>
                      Expires:<span>{bonusStats?.expires}</span>{" "}
                    </li>
                    <li>
                      Rollover Amount:
                      <span>
                        {bonusStats?.amount?.claimed} /{" "}
                        {bonusStats?.amount?.goalAmount}
                      </span>{" "}
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="main_card">
              {Array.isArray(bonusParts) &&
                bonusParts.map((item, id) => (
                  <div className="cards mt-5">
                    <div className=" gap-5">
                      <div className="d-flex amount_data">
                        <div>
                          Amount:
                          <span>{item?.creditableAmount}</span>{" "}
                        </div>
                        <div className="">
                          Status:
                          <span
                            className={`${
                              item?.status === "claimed"
                                ? "status_claimed"
                                : item?.status === "active"
                                ? "staus"
                                : "staus_pending"
                            }`}
                          >
                            {item?.status}
                          </span>{" "}
                        </div>
                        {item.status !== "claimed" &&
                          item.status !== "canceled" && (
                            <div
                              className="cancel_bonus"
                              onClick={() => handleCancelBonus(item.id)}
                            >
                              Cancel Bonus
                            </div>
                          )}
                      </div>
                      <div className="mt-3">
                        <ProgressBar
                          now={item.claimedAmount}
                          // label={item.claimedAmount}
                          max={item.goalAmount}
                          className="progressbar-section"
                        />
                        <div className="d-flex justify-content-between mt-2">
                          <span>{item.claimedAmount}</span>
                          <span>{item.goalAmount}</span>
                        </div>
                      </div>
                      {/* <div className="bonus">
                      <h6>BONUS</h6>
                      <div className="d-flex date_bonus">
                        <div className="reqDate">
                          Request Date:
                          <div className="date_new">{item.reqDate}</div>
                        </div>
                        <div className="reqDate">
                          Expire Data:
                          <div className="date_new">{item.expDate}</div>
                        </div>
                      </div>
                    </div> */}
                      {/* <div className="comments">
                      <Accordion>
                        <Accordion.Item eventKey={item.eventKey}>
                          <Accordion.Header className="">
                            Comments:
                          </Accordion.Header>
                          <Accordion.Body>{item.comment}</Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div> */}
                    </div>
                  </div>
                ))}
            </div>
          </Container>
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default BonusCard;
