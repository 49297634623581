import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllGateways,
  setSelectedGateway,
} from "../../../../redux/user/actions";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate } from "react-router-dom";
import DepositImg from "../../../../assets/images/icons/dc.png";

const WithDrawGatewaysList = () => {
  const { gatewaysList } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleGatewayClick = (item) => {
    dispatch(setSelectedGateway(item));
    navigate(`/withdrawGateway/${item.name}`);
  };

  useEffect(() => {
    dispatch(getAllGateways());
  }, []);
  return (
    <div>
      <HeaderAfterLogin />
      <main className="main">
        <div className="gatewaySection">
          <div className="gatewaypart">
            <Tabs
              defaultActiveKey="Deposit"
              id="uncontrolled-tab-example"
              className=""
            >
              <Tab eventKey="Deposit" title="Deposit">
                {gatewaysList?.withdraws
                  ?.sort((a, b) => a.order - b.order)
                  ?.filter((f) => f.type === "auto")
                  .map((item, index) => {
                    return (
                      <div
                        className="cards1"
                        onClick={() => {
                          handleGatewayClick(item);
                        }}
                        key={index}
                      >
                        <div className="card-icon">
                          <img src={DepositImg} />
                        </div>

                        <p>{item?.name}</p>
                      </div>
                    );
                  })}
              </Tab>
              <Tab eventKey="bank" title="Bank Transfer">
                {gatewaysList?.withdraws
                  ?.sort((a, b) => a.order - b.order)
                  ?.filter((f) => f.type === "manual")
                  .map((item, index) => {
                    return (
                      <div
                        className="cards1"
                        onClick={() => {
                          handleGatewayClick(item);
                        }}
                        key={index}
                      >
                        {" "}
                        <div className="card-icon">
                          <img src={DepositImg} />
                        </div>
                        <p>{item?.name}</p>
                      </div>
                    );
                  })}
              </Tab>
            </Tabs>
          </div>
        </div>
      </main>
    </div>
  );
};
export default WithDrawGatewaysList;
